﻿$flexboxgrid-breakpoints: sm size(s), md size(m), lg size(l), xl size(xl) !default;

.-d {
    &-p {
        &--xxs {
            padding: .5rem;
        }

        &--xs {
            padding: 1rem;
        }

        &--sm {
            padding: 1.5rem;
        }

        &--md {
            padding: 2.5rem;
        }

        &--lg {
            padding: 3.5rem;
        }

        &--xl {
            padding: 5rem;
        }

        &--xxl {
            padding: 7.5rem;
        }
    }

    &-mt {
        &--xxs {
            margin-top: .5rem;
        }

        &--xs {
            margin-top: 1rem;
        }

        &--sm {
            margin-top: 1.5rem;
        }

        &--md {
            margin-top: 2.5rem;
        }

        &--lg {
            margin-top: 3.5rem;
        }

        &--xl {
            margin-top: 5rem;
        }

        &--xxl {
            margin-top: 7.5rem;
        }

        &--auto {
            margin-top: auto;
        }
    }

    &-mb {
        &--xxs {
            margin-bottom: .5rem;
        }

        &--xs {
            margin-bottom: 1rem;
        }

        &--sm {
            margin-bottom: 1.5rem;
        }

        &--md {
            margin-bottom: 2.5rem;
        }

        &--lg {
            margin-bottom: 3.5rem;
        }

        &--xl {
            margin-bottom: 5rem;
        }

        &--xxl {
            margin-bottom: 7.5rem;
        }
    }

    &-ml {
        &--xxs {
            margin-left: .5rem;
        }

        &--xs {
            margin-left: 1rem;
        }

        &--sm {
            margin-left: 1.5rem;
        }

        &--md {
            margin-left: 2.5rem;
        }

        &--lg {
            margin-left: 3.5rem;
        }

        &--xl {
            margin-left: 5rem;
        }

        &--xxl {
            margin-left: 7.5rem;
        }
    }

    &-mr {
        &--xxs {
            margin-right: .5rem;
        }

        &--xs {
            margin-right: 1rem;
        }

        &--sm {
            margin-right: 1.5rem;
        }

        &--md {
            margin-right: 2.5rem;
        }

        &--lg {
            margin-right: 3.5rem;
        }

        &--xl {
            margin-right: 5rem;
        }

        &--xxl {
            margin-right: 7.5rem;
        }
    }

    &-pt {
        &--xxs {
            padding-top: .5rem;
        }

        &--xs {
            padding-top: 1rem;
        }

        &--sm {
            padding-top: 1.5rem;
        }

        &--md {
            padding-top: 2.5rem;
        }

        &--lg {
            padding-top: 3.5rem;
        }

        &--xl {
            padding-top: 5rem;
        }

        &--xxl {
            padding-top: 7.5rem;
        }
    }

    &-pb {
        &--xxs {
            padding-bottom: .5rem;
        }

        &--xs {
            padding-bottom: 1rem;
        }

        &--sm {
            padding-bottom: 1.5rem;
        }

        &--md {
            padding-bottom: 2.5rem;
        }

        &--lg {
            padding-bottom: 3.5rem;
        }

        &--xl {
            padding-bottom: 5rem;
        }

        &--xxl {
            padding-bottom: 7.5rem;
        }
    }

    &-pl {
        &--xxs {
            padding-left: .5rem;
        }

        &--xs {
            padding-left: 1rem;
        }

        &--sm {
            padding-left: 1.5rem;
        }

        &--md {
            padding-left: 2.5rem;
        }

        &--lg {
            padding-left: 3.5rem;
        }

        &--xl {
            padding-left: 5rem;
        }

        &--xxl {
            padding-left: 7.5rem;
        }
    }

    &-pr {
        &--xxs {
            padding-right: .5rem;
        }

        &--xs {
            padding-right: 1rem;
        }

        &--sm {
            padding-right: 1.5rem;
        }

        &--md {
            padding-right: 2.5rem;
        }

        &--lg {
            padding-right: 3.5rem;
        }

        &--xl {
            padding-right: 5rem;
        }

        &--xxl {
            padding-right: 7.5rem;
        }
    }

    &-plr {
        &--xxs {
            padding-left: .5rem;
            padding-right: .5rem;
        }

        &--xs {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &--sm {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        &--md {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        &--lg {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }

        &--xl {
            padding-left: 5rem;
            padding-right: 5rem;
        }

        &--xxl {
            padding-left: 7.5rem;
            padding-right: 7.5rem;
        }
    }
}

.-d-page--gutters {
    margin-bottom: 10.5vw;
    padding-top: 7.5vw;
}

.-d-margins {
    margin-bottom: 15vw;

    &--pico {
        margin-bottom: 1.5vw;
    }

    &--tiny {
        margin-bottom: 2.5vw;
    }

    &--narrow {
        margin-bottom: 5vw;
    }

    &--straight {
        margin-bottom: 10.5vw;
    }

    &--airy {
        margin-bottom: 22.5vw;
    }

    &--bulky {
        margin-bottom: 26.25vw;
    }

    &--extreme {
        margin-bottom: 30vw;
    }
}

@include mq(m) {
    .-d-page--gutters {
        margin-bottom: 3.5vw;
        padding-top: 2.5vw;
    }

    .-d-margins {
        margin-bottom: 5vw;

        &--pico {
            margin-bottom: .75vw;
        }

        &--tiny {
            margin-bottom: 1.25vw;
        }

        &--narrow {
            margin-bottom: 2.5vw;
        }

        &--straight {
            margin-bottom: 3.5vw;
        }

        &--airy {
            margin-bottom: 7.5vw;
        }

        &--bulky {
            margin-bottom: 11.25vw;
        }

        &--extreme {
            margin-bottom: 15vw;
        }
    }
}

$name: xs;

.-#{$name}-margins {
    margin-bottom: 15vw;

    &--pico {
        margin-bottom: 2.25vw;
    }

    &--tiny {
        margin-bottom: 3.75vw;
    }

    &--narrow {
        margin-bottom: 7.5vw;
    }

    &--straight {
        margin-bottom: 10.5vw;
    }

    &--airy {
        margin-bottom: 22.5vw;
    }

    &--bulky {
        margin-bottom: 26.25vw;
    }

    &--extreme {
        margin-bottom: 30vw;
    }
}

@each $breakpoint in $flexboxgrid-breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);

    @media only screen and (min-width: $size) {
        .-#{$name}-margins {
            margin-bottom: 5vw;

            &--pico {
                margin-bottom: .75vw;
            }

            &--tiny {
                margin-bottom: 1.25vw;
            }

            &--narrow {
                margin-bottom: 2.5vw;
            }

            &--straight {
                margin-bottom: 3.5vw;
            }

            &--airy {
                margin-bottom: 7.5vw;
            }

            &--bulky {
                margin-bottom: 11.25vw;
            }

            &--extreme {
                margin-bottom: 15vw;
            }
        }
    }
}
