﻿* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;

    &:focus {
        outline: none !important;
    }
}

html {
    height:100%;
    min-height: 100vh;
}

body {
    background-color: #ffffff;
    height: 100%;
}

.spider {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.extra-check {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

@include mq(m, max) {
    * {
        cursor: pointer;
    }
}
