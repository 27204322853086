﻿html {
    font-family: font('primary');
    font-size: 13px;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        padding: 0;
    }
}

a {
    @include transition(color ease-out .3s);
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
}

sup {
  font-size: 30%;
  top:-2em;  
}

.ty-primary {
    font-family: font(primary);
}

.ty-secondary {
    font-family: font(secondary);
}

.ty-tertiary {
    font-family: font(tertiary);
}

.ty-header {
    color: #ffffff;

    &.ty-header--nano {
        @include font-size((null: (12px, 15px)));
    }

    &.ty-header--pico {
        @include font-size((null: (14px, 17px)));
    }

    &.ty-header--tiny {
        @include font-size((null: (16px, 19px)));
    }

    &.ty-header--small {
        @include font-size((null: (20px, 24px)));
    }

    &.ty-header--compact {
        @include font-size((null: (20px, 25px), m: (25px, 30px)));
    }

    &.ty-header--medium {
        @include font-size((null: (21px, 30px), m: (30px, 36px)));
    }

    &.ty-header--big {
        @include font-size((null: (40px, 48px)));
    }

    &.ty-header--huge {
        @include font-size((null: (30px, 36px), m: (50px, 60px)));
    }

    &.ty-header--hyper {
        @include font-size((null: (35px, 38px), m: (60px, 70px)));
    }

    &.ty-header--secondary {
        color: rgba(0,0,0,.4);
    }

    &.ty-header--dubbed {
        position: relative;

        &.ty-centered {
            &:before {
                left: 50%;
                transform: translate(-50%,0);
                width: 100%;
            }
        }

        &:before {
            content:attr(data-title);
            color: rgba(204, 153, 72, .3);
            font-family: font(tertiary);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            font-weight: 400;
        }

        .ty-header--main {
            position: relative;
            z-index: 0;

            sup {
                font-size: 30%;
                top:-2em;
            }
        }

        .ty-header--bg {
            color: rgba(204, 153, 72, .3);
            font-family: font(tertiary);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            font-weight: 400;

            sup {
                font-size: 30%;
                top:-2em;
            }
        }
    }
}

.ty-paragraph {
    @include font-size((null: (12px, 1.5, 400)));
    color: #ffffff;

    &.ty-paragraph--mini {
        font-size: 11px;
    }

    &.ty-paragraph--small {
        font-size: 12px;
    }

    &.ty-paragraph--medium {
        font-size: 14px;
    }

    &.ty-paragraph--big {
        font-size: 16px;
    }

    &.ty-paragraph--huge {
        font-size: 16px;
    }

    &.ty-paragraph--warning {
        color: #b71c1c;
    }

    &.ty-paragraph--quote {
        background-color: palette('gray', 'pampas');
        text-align: center;
        font-style: italic;
        padding: 2vw 3vw;

        &:before, &:after {
            background-color: rgba(0,0,0, .5);
            content: '';
            display: block;
            height: 30px;
            margin-left: auto;
            margin-right: auto;
            width: 1px;
        }

        &:before {
            margin-bottom: .5rem;
        }

        &:after {
            margin-top: .5rem;
        }
    }

    &.ty-paragraph--flat {
        ul {
            padding-left: 0;

            li {
                list-style: none;
                margin-left: 0;
            }
        }

        a {
            &:after {
                width: 0 !important;
            }
        }
    }

    &.ty-paragraph--newspaper {
        column-count: 2;
        column-gap: 50px;
    }

    &.ty-paragraph--md-newspaper {
        @include mq(m) {
            column-count: 2;
            column-gap: 50px;
        }
    }

    &.ty-paragraph--beautify {
        ul {
            display: inline-block;
            padding-left: 0;

            li {
                list-style: none;
                margin-left: 0;
                text-align: left;

                &:before {
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTEgOCI+PHBvbHlnb24gZmlsbD0iIzBGMEUwRSIgcG9pbnRzPSI2IDQyNi4zMzMgMy4zMzMgNDIzLjY2NyAyIDQyNSA2IDQyOSAxMi42NjUgNDIyLjMzNCAxMS4zMzMgNDIxIDYgNDI2LjMzMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTQyMSkiLz48L3N2Zz4=);
                    content: '';
                    background-position: center center;
                    background-size: cover;
                    display: inline-block;
                    height: 8px;
                    margin-right: 5px;
                    width: 11px;
                }
            }
        }
    }

    &:not(.ty-paragraph--nodecor) {
        table {
            border: 1px solid #e8e8e8;
            display: table;
            table-layout: fixed;
            text-align: center;
            width: 100%;

            tr {
                &:last-of-type {
                    td {
                        border-bottom: 0;
                    }
                }

                td, th {
                    border-bottom: 1px solid #e8e8e8;
                    border-right: 1px solid #e8e8e8;
                    padding: .5em;

                    &:last-of-type {
                        border-right: 0;
                    }
                }
            }
        }
    }

    a:not(.bottone):not(.button) {
        color: inherit;
        display: inline-block;
        position: relative;

        &:after {
            background-color: currentColor;
            bottom: 5px;
            content: '';
            height: 1px;
            left: 50%;
            position: absolute;
            transition: all ease-out .25s;
            transform: translate(-50%,0);
            width: 100%;
        }

        &.ty-link--flat {
            &:after {
                content: none;
            }
        }
    }

    ul {
        display: inline-block;
        padding-left: 25px;

        li {
            list-style: disc;
            margin-left: 15px;
            text-align: left;
        }
    }

    b, strong {
        color:#CC9948;
        font-weight: 700;
    }

    p {
        margin: 0;
    }
}


.ty-aligned-vertical {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center
}

.ty-hyperlink {
    @include font-size((null: (11px, 14px, 400)));
    color: #0F0E0E;
    text-decoration: underline;
}

.ty-airy {
    line-height: 1.75 !important;
}

.ty-dubbed {
    line-height: 2 !important;
}

.ty-white {
    color: #ffffff;
}

.ty-middle-aligned {
    display: inline-flex;
    align-items: center;
}

.ty-column-aligned {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.ty-centered {
    text-align: center;
}

.ty-left {
    text-align: left;
}

.ty-right {
    text-align: right;
}

.ty-medium {
    font-weight: 500;
}

.ty-semibold {
    font-weight: 600;
}

.ty-bold {
    font-weight: 700;
}

.ty-light {
    font-weight: 300;
}

.ty-regular {
    font-weight: 400;
}

.ty-upper {
    text-transform: uppercase;
}

.ty-lower {
    text-transform: lowercase;
}

.ty-spacing {
    letter-spacing: 1px;
}

.ty-nowrap {
    white-space: nowrap;

    * {
        white-space: nowrap;
    }
}

.ty-inline-block {
    display: inline-block;
}

.ty-decored {
    text-decoration: underline;
}

.ty-palette--white {
    color: #ffffff;
}

.ty-palette--primary {
    color: $var-main-color;
}

.ty-palette--secondary {
    color: $var-side-color;
}

.ty-palette--lowcontrast {
    color: palette('gray', 'dusty');
}

.ty-palette--light {
    color: palette('dove');
}

.ty-palette--ultralight {
    color: palette('gray', 'alto');
}

.ty-palette--highlight {
    color: palette('red', 'torch');
}

.ty-palette--fluo {
    color: #08840F;
}

::selection {
    background-color: #000000;
    color: #ffffff;
}

@include mqhoverable() {
    .ty-paragraph {
        &.ty-paragraph--flat {
            a:not(.bottone):not(.button):hover:after {
                width: 100%;
            }

            a:not(.bottone):not(.button):after {
                width: 0;
            }
        }

        a:not(.bottone):not(.button):hover:after {
            width: 0;
        }
    }

    .ty-hyperlink {
        &:hover {
            text-decoration: none;
        }
    }
}

@include mq(m, max) {
    .ty-header.ty-header--dubbed.is-sm-centered:before {
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
    }
}

@include mq(m) {
    html {
        font-size: 12px;
    }
}
