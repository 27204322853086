﻿$grid-breakpoints: sm size(s), md size(m), lg size(l), xl size(xl) !default;

@mixin standard-formats {
    &-1by1 {
        @include alter-ratio(100%); //1
    }

    &-9by10 {
        @include alter-ratio(90%); //1,11
    }

    &-10by9 {
        @include alter-ratio(111%);
    }

    &-5by4 {
        @include alter-ratio(80%); //1,25
    }

    &-4by5 {
        @include alter-ratio(125%);
    }

    &-9by7 {
        @include alter-ratio(78%); //1,29
    }

    &-7by9 {
        @include alter-ratio(129%);
    }

    &-4by3 {
        @include alter-ratio(75%); //1,333333333333333
    }

    &-3by4 {
        @include alter-ratio(133%);
    }

    &-3by2 {
        @include alter-ratio(66.6667%); //1,5
    }

    &-2by3 {
        @include alter-ratio(150%);
    }

    &-5by3 {
        @include alter-ratio(60%); //1,666666666666667
    }

    &-3by5 {
        @include alter-ratio(167%);
    }

    &-7by4 {
        @include alter-ratio(57.14285714285714%); //1.75
    }

    &-4by7 {
        @include alter-ratio(175%);
    }

    &-16by9 {
        @include alter-ratio(56.25%); //1,777777777777778
    }

    &-9by16 {
        @include alter-ratio(177%);
    }

    &-2by1 {
        @include alter-ratio(50%); //2
    }

    &-1by2 {
        @include alter-ratio(200%);
    }

    &-9by4 {
        @include alter-ratio(44.44444444444444%); //2.25
    }

    &-4by9 {
        @include alter-ratio(225%);
    }

    &-10by3 {
        @include alter-ratio(30%); //3.333333333333333
    }

    &-3by10 {
        @include alter-ratio(333%);
    }

    &-full-height {
        height: 100%;

        &:before {
            content: none;
        }
    }
}

img[src=''],
img:not([src]) {
    opacity: 0;
}

img[data-sizes="auto"] {
    width: 100%;
}

img {
    @include transition(opacity .235s cubic-bezier(.4, 0, .2, 1));
    display: block;
    margin: 0 auto;
    max-width: 100%;
    outline: none;

    &.img--full {
        max-width: none;
        width: 100%;
    }

    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

.multimedia {
    display: block;

    &:before {
        content: '';
        display: block;
        position: relative;
        width: 100%;
        /*padding-top: 56.25%;*/
        z-index: z(below-content);
    }

    &.multimedia--format {
        @include standard-formats;
    }

    &.multimedia--shaded {
        .image {
            &:before {
                @include position(absolute, 0, 0, 0, 0, $z: z('above-content'));
                content: '';
                display: block;
                opacity: 1;
                background-image: linear-gradient(180deg, rgba(0, 0, 0, .25) 0%, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, .25) 100%);
            }
        }
    }

    &.multimedia--shaded-deep {
        .image {
            &:before {
                @include position(absolute, 0, 0, 0, 0, $z: z('above-content'));
                content: '';
                display: block;
                opacity: .3;
                background-color: #000000;
            }
        }
    }

    &.multimedia--shaded-deep-2 {
        .image {
            &:before {
                @include position(absolute, 0, 0, 0, 0, $z: z('above-content'));
                content: '';
                display: block;
                opacity: .5;
                background-color: #000000;
            }
        }
    }

    &.multimedia--image {
        overflow: hidden;
        position: relative;
        z-index: z(hack);

        &:after {
            @include spin-loader();
        }

        &:not(.transparent) {
            .image {
                background-color: #ffffff;
            }
        }

        .image {
            @include position(absolute, 0, 0, 0, 0, 1);
            @include transition(opacity .235s cubic-bezier(.4, 0, .2, 1));
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            opacity: 0;
            width: 100%;

            &.lazyload,
            &.lazyloading {
                opacity: 0;
            }

            &.ls-blur-up-img {

                &.lazyload,
                &.lazyloading {
                    opacity: 1;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    backdrop-filter: blur(10px);
                    /* apply the blur */
                    pointer-events: none;
                    /* make the overlay click-through */
                    @include transition(filter .235s);
                }
            }

            &.lazyloaded {
                opacity: 1;

                &.ls-blur-up-img:after {
                    backdrop-filter: blur(0);
                }
            }
        }
    }

    &.multimedia--video {
        height: 100%;
        position: relative;
        z-index: z(hack);

        .multimedia--video--wrapper {
            @include position(absolute, 0, 0, 0, 0, $z: 1);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;

            &:before {
                @include spin-loader();
            }

            .iframe-video {
                @include position(absolute, $center_x: true, $center_y: true, $z: 0);
                @include transition(all ease-out .5s);
                border: 0;
                display: block;
                height: 100%;
                margin: 0;
                min-height: 350px;
                opacity: 0;
                padding: 0;
                width: 102%;

                &.lazyload {
                    opacity: 0;
                }

                &.lazyloading {
                    opacity: 0;
                }

                &.lazyloaded {
                    opacity: 1;
                }
            }

            video {
                @include position(absolute, $center_x: true, $center_y: true);
                @include transition(all ease-out .5s, margin-top ease-out .001s);
                display: block;
                opacity: 0;
                width: 102%;

                &.lazyloaded {
                    opacity: 1;
                }
            }
        }
    }

    &.multimedia--loaded {

        &.multimedia--image,
        &.multimedia--video {
            &:after {
                //animation-play-state: paused;
                content: none;
            }
        }
    }

    &.no-loader {

        &.multimedia--image,
        &.multimedia--video {
            &:after {
                content: none;
            }
        }
    }

    &__caption {
        position: absolute;
        z-index: z(above-content);

        &-bc {
            @include transform(translate(-50%, 0));
            left: 50%;
            bottom: 0;
            width: 90%;
        }

        &-cc {
            @include transform(translate(-50%, -50%));
            left: 50%;
            top: 50%;
            width: 90%;
        }

        &-tc {
            @include transform(translate(-50%, 0));
            left: 50%;
            top: 0;
            width: 90%;
        }
    }
}


$name: xs;

.multimedia--#{$name}-format {
    @include standard-formats;
}

@each $breakpoint in $grid-breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);

    @media only screen and (min-width: $size) {
        .multimedia--#{$name}-format {
            @include standard-formats;
        }
    }
}