﻿.-d-stage--infinite-skinny {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(stage(infinite-skinny), px);
    width: map-get(map-get(stage(infinite-skinny), percentage), xs);

    &-ff {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get(stage(infinite-skinny), px);
        width: 100%;
    }
}

.-d-stage--super-skinny {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(stage(super-skinny), px);
    width: map-get(map-get(stage(super-skinny), percentage), xs);

    &-ff {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get(stage(super-skinny), px);
        width: 100%;
    }
}

.-d-stage--skinny {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(stage(skinny), px);
    width: map-get(map-get(stage(skinny), percentage), xs);

    &-ff {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get(stage(skinny), px);
        width: 100%;
    }
}

.-d-stage--narrow {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(stage(narrow), px);
    width: map-get(map-get(stage(narrow), percentage), xs);

    &-ff {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get(stage(narrow), px);
        width: 100%;
    }
}

.-d-stage--straight {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(stage(straight), px);
    width: map-get(map-get(stage(straight), percentage), xs);

    &-ff {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get(stage(straight), px);
        width: 100%;
    }
}

.-d-stage--loose {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(stage(loose), px);
    width: map-get(map-get(stage(loose), percentage), xs);

    &-ff {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get(stage(loose), px);
        width: 100%;
    }
}

.-d-stage--oversize {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(stage(oversize), px);
    width: map-get(map-get(stage(oversize), percentage), xs);

    &-ff {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get(stage(oversize), px);
        width: 100%;
    }
}

.-d-stage--fixed {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
}

@include mq(ml) {
    .-d-stage--infinite-skinny {
        width: map-get(map-get(stage(infinite-skinny), percentage), ml);
    }

    .-d-stage--super-skinny {
        width: map-get(map-get(stage(super-skinny), percentage), ml);
    }

    .-d-stage--skinny {
        width: map-get(map-get(stage(skinny), percentage), ml);
    }

    .-d-stage--narrow {
        width: map-get(map-get(stage(narrow), percentage), ml);
    }

    .-d-stage--straight {
        width: map-get(map-get(stage(straight), percentage), ml);
    }

    .-d-stage--loose {
        width: map-get(map-get(stage(loose), percentage), ml);
    }

    .-d-stage--oversize {
        width: map-get(map-get(stage(oversize), percentage), ml);
    }

    .-d-stage--fixed {
        width: calc(100% - 80px);
    }
}
