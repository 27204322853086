﻿$states-breakpoints: (sm size(m), md size(l), lg size(xxxl));

.js--hide {
    display: none !important;
}

.is-forbidden {
    opacity: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
}

.is-hide {
    display: none !important;
}

.is-hidable {
    display: none;

    &.js--active {
        display: block;
    }
}

.is-centered {
    text-align: center;
}

.is-collapsable {
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    -webkit-transition: all ease .25s;
    -moz-transition: all ease .25s;
    -ms-transition: all ease .25s;
    -o-transition: all ease .25s;
    transition: all ease .25s;

    &.is-exploded {
        max-height: 2500px;
        overflow: hidden;
        -webkit-transition: all ease .25s;
        -moz-transition: all ease .25s;
        -ms-transition: all ease .25s;
        -o-transition: all ease .25s;
        transition: all ease .25s;
    }
}

.is-loading:not(.button) {
    position: relative;

    &:after {
        @include spin-loader();
        opacity: 1;
        visibility: visible;
        z-index: z(highest);
    }

    &:before {
        background-color: #ffffff;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        -webkit-transition: all ease-out .3s;
        transition: all ease-out .3s;
        visibility: visible;
        width: 100%;
        z-index: z(sub-highest);
    }
}

@each $state-breakpoint in $states-breakpoints {
    $name: nth($state-breakpoint, 1);
    $size: nth($state-breakpoint, 2);

    @include mq($size, max) {
        .is-#{$name}-forbidden {
            opacity: 0 !important;
            height: 0 !important;
            display: none !important;
            overflow: hidden !important;
        }

        .is-#{$name}-hide {
            display: none !important;
        }

        .is-#{$name}-centered {
            text-align: center;
        }

        .is-#{$name}-collapsable {
            max-height: 0;
            overflow: hidden;
            padding-bottom: 0;
            padding-top: 0;
            -webkit-transition: all cubic-bezier(0,1,0,1) .75s;
            -moz-transition: all cubic-bezier(0,1,0,1) .75s;
            -ms-transition: all cubic-bezier(0,1,0,1) .75s;
            -o-transition: all cubic-bezier(0,1,0,1) .75s;
            transition: all cubic-bezier(0,1,0,1) .75s;

            &.is-#{$name}-exploded {
                max-height: 2500px;
                overflow: hidden;
                -webkit-transition: all ease-in 1s;
                -moz-transition: all ease-in 1s;
                -ms-transition: all ease-in 1s;
                -o-transition: all ease-in 1s;
                transition: all ease-in 1s;
            }
        }
    }
}
