@import '../base/config', '../base/helpers/utilities';

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


html,
body {
    min-height: vh(100);
}

body {
    background-color: #0E0D0B;
    background-image: url(../images/texture.png);
    background-repeat: repeat;
    background-position: left top;

    &:not(.body--home) {
        header .logo {
            position: fixed;
        }
    }

    &.body--home.body--deep-scrolled {
        header .navbar .lazylogo {
            opacity: 1;
            visibility: visible;
        }
    }
}

.w100 {
    width: 100%;
}

.line-break {
    white-space: pre-wrap;
}

header {
    &.header--compact {
        .logo {
            width: 140px;
            top: 10px;
            transform: translate(-50%, 0);
        }
    }

    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 175px;
        z-index: z(sub-navbar);
    }

    .navbar {
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: z(navbar);

        &.navbar--active {
            .menu__wrapper {
                opacity: 1;
                visibility: visible;
            }
        }

        .menu__wrapper {
            left: 0;
            height: vh(100);
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            transition: all ease-out .25s;

            .menu__bg {
                background-color: #000000;
                background-image: url(../images/illustrazione.png);
                background-position: center bottom;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: -1;
            }

            .menu {
                padding: 20vw 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                height: vh(100);

                li {
                    margin-bottom: 5rem;
                }
            }

            .menu__languages {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        .lazylogo {
            width: 90px;
            top: 10px;
            transform: translate(-50%, 0);
            position: absolute;
            left: 50%;
            opacity: 0;
            visibility: hidden;
            transition: all ease-out .25s;
            z-index: z(sub-navbar);
        }
    }

    .sidebar__right,
    .sidebar__left {
        position: absolute;
        top: 1rem;

        .ty-aligned-vertical {
            height: 40px;
        }
    }

    .sidebar__left {
        left: 1rem;
    }

    .sidebar__right {
        right: 1rem;
    }
}

.toggle {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    bottom: 0;
    padding-bottom: 12px;
    left: 50%;
    text-align: center;
    transform: translate(-50%, 0);
    width: 90%;
    z-index: #{z(midline) + 2};

    &__bg {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: -1;

        .icon {
            // transform-origin: bottom;
            // transform: scale(.75);
            height: 110px;
            width: 320px;
        }
    }

    .toggle-switch {
        display: inline-block;
        background: transparent;
        border-radius: 18px;
        border: 2px solid #ffffff;
        width: 82px;
        height: 36px;
        position: relative;
        vertical-align: middle;
        transition: background 0.25s;

        &:before,
        &:after {
            content: "";
        }

        &:before {
            display: block;
            background-color: #ffffff;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 7px;
            left: 9px;
            transition: left 0.25s;
        }
    }

    .toggle-checkbox {
        position: absolute;
        visibility: hidden;

        &:checked+.toggle-switch {
            background: transparent;

            &:before {
                left: 51px;
            }
        }
    }

    .toggle-label {
        margin-left: 5px;
        position: relative;
        top: 2px;
    }
}

.galleries {
    position: relative;

    &.galleries--FH {
        height: vh(100);

        .gallery {
            opacity: 0;
            visibility: hidden;

            &.gallery--active {
                opacity: 1;
                visibility: visible;
                z-index: #{z(midline) + 1};
            }
        }
    }

    &.galleries--HB {
        height: vh(40);
        min-height: 400px;
    }

    .gallery {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: all ease-out .25s;
        width: 100%;
        z-index: z(midline);

        .gallery__swiper {
            height: 100%;
            width: 100%;
        }

        .gallery__header {
            left: 50%;
            position: absolute;
            bottom: 20vh;
            max-width: 400px;
            text-align: center;
            transform: translate(-50%, 0);
            width: 90%;
            z-index: z(midline);
        }
    }
}

.intro {
    padding: 7.5rem 0 5rem;

    .intro__swiper {
        display: inline-block;
        width: 100%;
    }

    .intro__swiper,
    .multimedia {
        max-width: 450px;
    }
}

.highlights {
    &__list {
        .row {
            .highlights__card {
                margin: 0;
                max-width: 670px;
                width: 95%;
            }

            &:nth-child(3n),
            &:nth-child(3n - 1) {
                .highlights__card {
                    margin: 0 auto;
                    max-width: 390px;
                    width: 95%;
                }
            }
        }
    }
}

.box-highlights {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3rem 2rem;
    height: 100%;
}

.menu-tabs {
    .tab-headers {
        text-align: center;
    }

    .tab-header {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        color: #ffffff;
        font-size: 20px;
        font-family: font(secondary);
        text-transform: uppercase;
        margin: 0 10px;
        padding: 0;
        position: relative;

        &:hover,
        &.tab-header--active {
            &:after {
                width: 100%;
            }
        }

        &:after {
            background-color: #CC9948;
            bottom: -10px;
            content: '';
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            transform: translate(-50%, 0);
            transition: all ease-out .25s;
            width: 0;
        }
    }
}

.mainmenu {
    .summary {
        display: inline-block;
        max-width: 285px;
        padding: 66px 0;
        position: sticky;
        top: 70px;
        width: 100%;

        &:before,
        &:after {
            background-size: 100%;
            content: '';
            height: 66px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:before {
            top: 0;
            background-image: url(../images/top.png);
        }

        &:after {
            bottom: 0;
            background-image: url(../images/bottom.png);
        }

        .summary__core {
            background-image: url(../images/middle.png);
            background-size: 100%;
            background-repeat: repeat;
            background-position: center top;
            text-align: left;
            padding: 0 55px;

            h2 {
                color: #ffffff;
                cursor: pointer;
                display: inline-block;
                font-size: 15px;
                line-height: 35px;
                // line-height: 40px;
                position: relative;
                text-transform: uppercase;
                text-align: left;
                transition: all ease-out .25s;

                &:hover {
                    color: #CC9948;

                    &:after {
                        width: 100%;
                    }
                }

                &:after {
                    background-color: #CC9948;
                    bottom: 5px;
                    content: '';
                    display: block;
                    height: 2px;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%, 0);
                    transition: all ease-out .25s;
                    width: 0;
                }
            }
        }
    }

    .list {
        padding-left: 1rem;
        padding-right: 1rem;

        .list__wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        .list__header {
            color: #CC9948;
            font-size: 16px;
            font-weight: 600;
            line-height: 33px;
            overflow-wrap: break-word;
            text-transform: uppercase;
            text-align: left;
            max-width: 80%;

            &.list__header-price {
                margin-left: auto;
                word-break: keep-all;
            }

            .icon {
                fill: #CC9948;
            }
        }

        .list__filler {
            border-bottom-style: dotted;
            border-color: #979797;
            display: inline-flex;
            flex-grow: 1;
            align-self: baseline;
            height: 22px;
            margin: 0 10px;
        }

        .list__description {
            color: #ffffff;
            font-size: 16px;
            line-height: 33px;
            text-align: left;
            max-width: 80%;

            b {
                color: #CC9948;
                font-weight: 400;
            }

            i {
                font-size: 13px;
            }
        }
    }
}

.socialwall {
    .multimedia {
        background-color: #fff;
        display: inline-block;
        margin-right: 15px;
        width: 100%;
    }
}

.socialicon {
    .icon {
        fill: #ffffff;
        transition: all ease-out .25s;

        &:hover {
            fill: #CC9948;
        }
    }
}

.credits {
    border-top: 1px solid rgba(255, 255, 255, .16);
    color: rgba(255, 255, 255, 0.5) !important;
    margin: 0 auto;
    padding: 10px 0;
    width: 90vw;

    .ty-paragraph,
    a {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    a {
        transition: all ease-out .25s;

        &:hover {
            color: #ffffff !important;
        }
    }
}

.types__swiper {
    --swiper-theme-color: #ffffff !important;
    --swiper-navigation-size: 30px !important;
}

.scroll-top-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    padding: 10px 10px;
    background-color: #CC9948;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    z-index: 1000;
    height: 34px;
    width: 34px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all ease-out .25s;

    &:hover {
        background-color: #CC9948;
    }

    &.scroll-top-button--visible {
        opacity: 1;
        visibility: visible;
    }
}

.revervebutton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 10px;
    background-color: #CC9948;
    color: white;
    border: none;
    cursor: pointer;
    z-index: 1000;
    height: 50px;
    width: 100%;
    text-align: center;
    display: flex;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase;
    transform: translate(0, 100%);
    transition: all ease-out .25s;
    align-items: center;

    &:hover {
        background-color: #CC9948;
    }

    &.revervebutton--visible {
        transform: translate(0, 0);
    }
}

[data-aos="reveal-down"] {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);

    &.aos-animate {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}


@keyframes scales {
    from {
        transform: scale(0.98)
    }

    to {
        transform: scale(1)
    }
}

@keyframes rotatepao {
    0% {
        transform: rotate(0deg)
    }

    50%,
    60% {
        transform: rotate(-20deg)
    }

    100% {
        transform: rotate(0deg)
    }

}

@keyframes olhos {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(30deg);
    }
}

@keyframes left-sparks {
    0% {
        opacity: 0;
    }
}

.notfound {
    background-color: #ffffff;
    height: 100vh;

    .fundo {
        animation: scales 3s alternate infinite;
        transform-origin: center;
    }

    .pao-baixo {
        animation: rotatepao 14s cubic-bezier(.1, .49, .41, .97) infinite;
        transform-origin: center;
    }

    .pao-cima {
        animation: rotatepao 7s 1s cubic-bezier(.1, .49, .41, .97) infinite;
        transform-origin: center;
    }

    .olhos {
        animation: olhos 2s alternate infinite;
        transform-origin: center;
    }

    .left-sparks {
        animation: left-sparks 4s alternate infinite;
        transform-origin: 150px 156px;
    }

    .right-sparks {
        animation: left-sparks 4s alternate infinite;
        transform-origin: 310px 150px;
    }

    .olhos {
        animation: olhos 2s alternate infinite;
        transform-origin: center;
    }

    .main {

        min-height: 600px;
        margin: 0px auto;
        width: auto;
        max-width: 460px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .path {
        stroke-dasharray: 300;
        stroke-dashoffset: 300;
        animation: dash 4s alternate infinite;
    }

    @keyframes dash {

        0%,
        30% {
            fill: 4B4B62;
            stroke-dashoffset: 0;
        }

        80%,
        100% {
            fill: transparent;
            stroke-dashoffset: -200;
        }


    }
}

@include mq(m, max) {
    body {

        .iubenda-tp-alert-btn[data-tp-float]:not([data-tp-anchored]),
        .iubenda-tp-btn[data-tp-float]:not([data-tp-anchored]),
        .iubenda-uspr-btn[data-tp-float]:not([data-tp-anchored]) {
            transition: all ease-out .25s !important;
        }

        &.body--scrolled:not(.body--contact) {

            .iubenda-tp-alert-btn[data-tp-float]:not([data-tp-anchored]),
            .iubenda-tp-btn[data-tp-float]:not([data-tp-anchored]),
            .iubenda-uspr-btn[data-tp-float]:not([data-tp-anchored]) {
                margin-bottom: 66px !important;
            }

            .scroll-top-button {
                bottom: 66px;
            }
        }

        &:not(.body--home) {
            header .logo {
                width: 90px;
            }
        }

        &:not(.body--contact) #root {
            padding-bottom: 90px;
        }

        // &.body--menu-float {
        //     .mainmenu .summary {
        //         position: fixed;
        //         top: 0;
        //         left: 0;
        //         width: auto;
        //         right: 0;
        //         z-index: 1000;
        //         background: black;
        //         max-width: 100%;
        //         max-height: 50px;
        //         overflow: hidden;

        //         .summary__core {
        //             max-height: 35px;
        //             overflow: hidden; 
        //         }
        //     }
        // }
    }

    footer {
        .ty-header--compact.ty-secondary {
            font-size: 12px;
            line-height: 15px;
        }
    }

    .galleries {
        .gallery {
            .size-m.multimedia {
                display: none !important;
            }
        }
    }
}

@include mq(m) {
    .revervebutton {
        display: none;
    }

    .scroll-top-button {
        bottom: 16px;
    }

    header {
        .logo {
            width: 350px;
            z-index: z(sur-navbar);
        }

        &.header--compact {
            .logo {
                width: 100px;
            }
        }

        .navbar {
            .menu__wrapper {
                height: vh(50);

                .menu {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 1100px;
                    margin: 0 auto;
                    left: 50%;
                    padding: 0;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    width: 100%;

                    li {
                        margin-bottom: 0;
                    }
                }
            }

            .lazylogo {
                width: 100px;
            }
        }
    }

    .intro {
        padding: 7.5rem 0 15rem;
        ;
    }

    .highlights {
        &__list {
            .row {
                &:not(:first-of-type) {
                    margin-top: -8rem;
                }
            }
        }
    }

    .box-highlights {
        padding: 4rem 3rem;
    }

    .mainmenu {
        .list {
            padding-left: 6rem;
            padding-right: 6rem;
        }
    }

    .legend {
        padding-left: 6rem;
    }
}