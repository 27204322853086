﻿//
// -- Start editing -- //
//

// Set the number of columns you want to use on your layout.
$flexboxgrid-grid-columns: 12;
// Set the gutter between columns.
$flexboxgrid-gutter-width: 1.5rem;
$flexboxgrid-gutterthiner-factor: .25;
$flexboxgrid-gutterwider-factor: 1.5;
// Set a margin for the container sides.
$flexboxgrid-outer-margin: 4rem;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$flexboxgrid-breakpoints: sm size(s) calc(size(s) - 2rem), md size(m) calc(size(m) - 2rem), lg size(l) calc(size(l) - 2rem), xl size(xl) calc(size(xl) - 2rem);
$flexboxgrid-max-width: 1440px;

//
// -- Stop editing -- //
//

$gutter-compensation: $flexboxgrid-gutter-width * .5 * -1;
$half-gutter-width: $flexboxgrid-gutter-width * .5;

.wrapper {
    box-sizing: border-box;
    max-width: $flexboxgrid-max-width;
    margin: 0 auto;
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-right: $flexboxgrid-outer-margin;
    padding-left: $flexboxgrid-outer-margin;
}

.row-wrapper {
    padding-right: #{$gutter-compensation * -1};
    padding-left: #{$gutter-compensation * -1};
}

.row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $gutter-compensation;
    margin-left: $gutter-compensation;

    &.row--separated {
        margin-bottom: $flexboxgrid-gutter-width;
    }
}


@mixin flexboxgrid-sass-col-common {
    box-sizing: border-box;
    // split flex: 0 0 auto into individual props
    flex-grow: 0;
    flex-shrink: 0;
    // we leave flex-basis: auto out of common because
    // in some spots we need it and some we dont
    // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
}

$name: xs;

.row {
    &.row-#{$name}--flat {
        margin-left: 0;
        margin-right: 0;

        > [class*="col-#{$name}"] {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.row-#{$name}--separated {
        margin-bottom: $flexboxgrid-gutter-width;
    }

    &--thin {
        margin-right: $flexboxgrid-gutter-width * $flexboxgrid-gutterthiner-factor * -1;
        margin-left: $flexboxgrid-gutter-width * $flexboxgrid-gutterthiner-factor * -1;

        > [class*="col-#{$name}"] {
            padding-right: $flexboxgrid-gutter-width * $flexboxgrid-gutterthiner-factor;
            padding-left: $flexboxgrid-gutter-width * $flexboxgrid-gutterthiner-factor;
        }

        &.row-#{$name}--separated {
            margin-bottom: $flexboxgrid-gutter-width * $flexboxgrid-gutterthiner-factor;
        }
    }

    &--wide {
        margin-right: $flexboxgrid-gutter-width * $flexboxgrid-gutterwider-factor * -1;
        margin-left: $flexboxgrid-gutter-width * $flexboxgrid-gutterwider-factor * -1;

        > [class*="col-#{$name}"] {
            padding-right: $flexboxgrid-gutter-width * $flexboxgrid-gutterwider-factor;
            padding-left: $flexboxgrid-gutter-width * $flexboxgrid-gutterwider-factor;
        }

        &.row-#{$name}--separated {
            margin-bottom: $flexboxgrid-gutter-width * $flexboxgrid-gutterwider-factor;
        }
    }
}

.row.#{$name}-reverse {
    flex-direction: row-reverse;
}

.row.#{$name}-bycolumns {
    flex-direction: column;
}

.col.#{$name}-reverse {
    flex-direction: column-reverse;
}

[class*="col-#{$name}"] {
    @include flexboxgrid-sass-col-common;
    flex-basis: auto;
}

@for $i from 1 through $flexboxgrid-grid-columns {
    .col-#{$name}-#{$i} {
        flex-basis: 100% / $flexboxgrid-grid-columns * $i;
        max-width: 100% / $flexboxgrid-grid-columns * $i;
    }
}

@for $i from 0 through $flexboxgrid-grid-columns {
    .col-#{$name}-offset-#{$i} {
        @if $i == 0 {
            margin-left: 0;
        }
        @else {
            margin-left: 100% / $flexboxgrid-grid-columns * $i;
        }
    }
}

.col-#{$name} {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}


.stretch-#{$name} {
    align-items: stretch;
}

.start-#{$name} {
    justify-content: flex-start;
    text-align: left;
}

.center-#{$name} {
    justify-content: center;
    text-align: center;
}

.end-#{$name} {
    justify-content: flex-end;
    text-align: right;
}

.top-#{$name} {
    align-items: flex-start;
}

.middle-#{$name} {
    align-items: center;
}

.bottom-#{$name} {
    align-items: flex-end;
}

.around-#{$name} {
    justify-content: space-around;
}

.between-#{$name} {
    justify-content: space-between;
}

.first-#{$name} {
    order: -1;
}

.last-#{$name} {
    order: 1;
}

.self-end-#{$name} {
    margin-left: auto;
}

@each $flexboxgrid-breakpoint in $flexboxgrid-breakpoints {
    $name: nth($flexboxgrid-breakpoint, 1);
    $size: nth($flexboxgrid-breakpoint, 2);
    $container: nth($flexboxgrid-breakpoint, 3);

    @media only screen and (min-width: $size) {
        .container {
            width: #{$container};
        }

        .row {
            &.row-#{$name}--flat {
                margin-left: 0;
                margin-right: 0;

                > [class*="col-#{$name}"] {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        .row.#{$name}-reverse {
            flex-direction: row-reverse;
        }

        .col.#{$name}-reverse {
            flex-direction: column-reverse;
        }

        [class*="col-#{$name}"] {
            @include flexboxgrid-sass-col-common;
            flex-basis: auto;
        }

        @for $i from 1 through $flexboxgrid-grid-columns {
            .col-#{$name}-#{$i} {
                flex-basis: 100% / $flexboxgrid-grid-columns * $i;
                max-width: 100% / $flexboxgrid-grid-columns * $i;
            }
        }

        @for $i from 0 through $flexboxgrid-grid-columns {
            .col-#{$name}-offset-#{$i} {
                @if $i == 0 {
                    margin-left: 0;
                }
                @else {
                    margin-left: 100% / $flexboxgrid-grid-columns * $i;
                }
            }
        }

        .col-#{$name} {
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
        }

        .stretch-#{$name} {
            align-items: stretch;
        }

        .start-#{$name} {
            justify-content: flex-start;
            text-align: left;
        }

        .center-#{$name} {
            justify-content: center;
            text-align: center;
        }

        .end-#{$name} {
            justify-content: flex-end;
            text-align: right;
        }

        .top-#{$name} {
            align-items: flex-start;
        }

        .middle-#{$name} {
            align-items: center;
        }

        .bottom-#{$name} {
            align-items: flex-end;
        }

        .around-#{$name} {
            justify-content: space-around;
        }

        .between-#{$name} {
            justify-content: space-between;
        }

        .first-#{$name} {
            order: -1;
        }

        .last-#{$name} {
            order: 1;
        }

        .self-end-#{$name} {
            margin-left: auto;
        }
    }
}
