﻿/* FUNCTIONS */

@function vendor-args($arg, $vendor) {
    $result: ();

    @each $i in $arg {
        @if $i == transform {
            $result: append($result, #{$vendor}$i);
        }
        @else {
            $result: append($result, $i);
        }
    }

    @return $result;
}

@function vh($quantity) {
    @return calc(var(--vh, 1vh) * #{$quantity});
}

@function vw($quantity) {
    @return calc(var(--vw, 1vw) * #{$quantity});
}

/* UTILITY */

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type == max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
    @else {
        @if $type == max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin mqrange($min, $max) {
    @if map_has_key($breakpoints, $min) and map_has_key($breakpoints, $max) {
        $min: map_get($breakpoints, $min);
        $max: map_get($breakpoints, $max);

        $max: $max - 1;

        @media only screen and (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
}

@mixin mqhoverable() {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin placeholder($font, $color, $upper: false, $fs: false, $weight: false, $letter-sp: false) {
    color: $color !important;
    font-family: $font !important;

    @if($fs) {
        font-size: $fs;
    }

    @if($weight) {
        font-weight: $weight;
    }

    @if($letter-sp) {
        letter-spacing: $letter-sp;
    }

    opacity: 1 !important;
    filter: alpha(opacity=100) !important;

    @if ($upper) {
        text-transform: uppercase;
    }
}

@mixin placeholder-set($font, $color, $upper: false, $fs: false, $weight: false, $letter-sp: false) {
    input::-webkit-input-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    input:-moz-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    input::placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    textarea::-webkit-input-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    textarea:-moz-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    .placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    ::-webkit-input-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    :-moz-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    ::-moz-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }

    :-ms-input-placeholder {
        @include placeholder($font, $color, $upper, $fs, $weight, $letter-sp);
    }
}

@mixin position($position, $top: false, $left: false, $bottom: false, $right: false, $z: false, $center_x: false, $center_y: false) {
    @if $bottom {
        bottom: $bottom;
    }

    @if $left {
        left: $left;
    }

    @if $position {
        position: $position;
    }

    @if $right {
        right: $right;
    }

    @if $top {
        top: $top;
    }

    @if $center_x and $center_y {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    @else {
        @if $center_x {
            left: 50%;
            transform: translate(-50%, 0);
        }

        @if $center_y {
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    @if $z {
        z-index: $z;
    }
}

@mixin typography($color: false, $size: false, $lh: false, $weight: false, $family: false, $align: false, $style: false) {
    @if $color {
        color: $color;
    }

    @if $family {
        font-family: $family;
    }

    @if $size {
        font-size: $size;
    }

    @if $weight {
        font-weight: $weight;
    }

    @if $lh {
        line-height: $lh;
    }

    @if $align {
        text-align: $align;
    }

    @if $style {
        font-style: $style;
    }
}

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            @include make-font-size($fs-font-size);
        }
        @else {
            // If $fs-font-size is a key that exists in
            // $fs-breakpoints, use the value
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }

            @media screen and (min-width: $fs-breakpoint) {
                @include make-font-size($fs-font-size);
            }
        }
    }
}

@mixin make-font-size($fs-font-size) {
    @if type-of($fs-font-size) == "list" {
        font-size: nth($fs-font-size, 1);
        // If $fs-font-size is a list, include
        // both font-size and line-height
        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }

        @if (length($fs-font-size) > 2) {
            font-weight: nth($fs-font-size, 3);
        }
    }
    @else {
        font-size: $fs-font-size;
    }
}

@mixin alter-ratio($ratio, $pseudo: true) {
    @if $pseudo {
        &:before {
            padding-top: $ratio;
        }
    }
    @else {
        padding-top: $ratio;
    }
}

@mixin blur-effect {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -ms-filter: blur(3px);
    -o-filter: blur(3px);
    filter: url("data:image/svg+xml;utf8,<svg height='0' xmlns='http://www.w3.org/2000/svg'><filter id='svgBlur' x='-5%' y='-5%' width='110%' height='110%'><feGaussianBlur in='SourceGraphic' stdDeviation='5'/></filter></svg>#svgBlur");
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius = '3');
    filter: blur(3px);
}
/* VENDORS */

@mixin vendor($property, $args...) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
        $out: null;

        @each $arg in nth($args,1) {
            $out: ($out, vendor-args($arg, $vendor));
        }

        #{$vendor}#{$property}: $out;
    }
}

@mixin animation($args...) {
    @include vendor(animation, $args);
}

@mixin transition($args...) {
    @include vendor(transition, $args);
}

@mixin transform($args...) {
    @include vendor(transform, $args);
}

@mixin transform-origin($args...) {
    @include vendor(transform-origin, $args);
}
/* EFFECTS */

@mixin fade-out($transition: false) {
    opacity: 0;
    visibility: hidden;

    @if($transition) {
        @include transition(opacity .25s ease-out, visibility .25s ease-out);
    }
}

@mixin fade-in($transition: false) {
    opacity: 1;
    visibility: visible;

    @if($transition) {
        @include transition(opacity .25s ease-out, visibility .25s ease-out);
    }
}

@mixin grayscale {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
    filter: gray;
    -webkit-filter: grayscale(1);
}

@mixin no-grayscale {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    -o-filter: grayscale(0);
    filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0' /></filter></svg>#greyscale");
    filter: gray;
    -webkit-filter: grayscale(0);
}
/* ANIMATIONS */
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@include keyframes(spinAround) {
    from {
        @include transform(rotate(0));
    }

    to {
        @include transform(rotate(359deg));
    }
}
/* ELEMENTS */
@mixin spin-loader {
    @include animation(spinAround .5s infinite linear);
    border: 2px solid #000;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    position: absolute !important;
    width: 20px;
    z-index: -1;
}

@mixin underlining($height, $color) {
    @include transition(all ease .3s);
    background-color: $color;
    height: $height;
    content: '';
    display: block;
    width: 0;
}

@mixin triangle($direction, $sizeH, $sizeV, $color) {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    -moz-transform: scale(.9999);

    @if $direction == top {
        border-width: 0 $sizeV $sizeH $sizeV;
        border-color: transparent transparent $color transparent;
    }

    @if $direction == bottom {
        border-width: $sizeV $sizeH 0 $sizeV;
        border-color: $color transparent transparent transparent;
    }

    @if $direction == left {
        border-width: $sizeV $sizeH $sizeV 0;
        border-color: transparent $color transparent transparent;
    }

    @if $direction == right {
        border-width: $sizeV 0 $sizeV $sizeH;
        border-color: transparent transparent transparent $color;
    }

    @if $direction == topright {
        border-width: 0 $sizeH $sizeV 0;
        border-color: transparent $color transparent transparent;
    }

    @if $direction == bottomright {
        border-width: 0 0 $sizeH $sizeV;
        border-color: transparent transparent $color transparent;
    }

    @if $direction == bottomleft {
        border-width: $sizeH 0 0 $sizeV;
        border-color: transparent transparent transparent $color;
    }

    @if $direction == topleft {
        border-width: $sizeH $sizeV 0 0;
        border-color: $color transparent transparent transparent;
    }
}

@mixin button($bg, $bg-over, $color, $height, $font-size, $border-radius: false, $border: false, $color-over: false, $xs-padding: false) {
    @include transition(background-color ease-out .25s);
    @include inline-flex;
    @include align-items(center);
    @include justify-content(center);
    background-color: $bg;
    border: 0;

    @if($border) {
        border: $border;
    }

    @if($border-radius) {
        border-radius: $border-radius;
    }

    color: $color;
    cursor: pointer;
    font-size: $font-size;
    height: $height;
    min-width: 100px;

    @if($xs-padding) {
        padding: $xs-padding;
    }
    @else {
        padding: 0 8%;
    }

    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    z-index: 0;

    @content;

    @include mq(m) {
        padding: 0 25px;

        &:hover {
            background-color: $bg-over;

            @if($color-over) {
                color: $color-over;
            }
        }
    }
}
