﻿.size-xs {
    display: block;
}

.size-inline-xs {
    display: inline-flex;
}

.size-s, .size-sm, .size-m, .size-ml, .size-l, .size-xl, .size-xxl, .size-xxxl,
.size-inline-s, .size-inline-sm, .size-inline-m, .size-inline-ml, .size-inline-l, .size-inline-xl, .size-inline-xxl, .size-inline-xxxl {
    display: none;
}

@include mq(s) {
    .size-xs-only {
        display: none !important;
    }

    .size-s {
        display: block;
    }

    .size-inline-s {
        display: inline-flex;
    }
}

@include mq(sm) {
    .size-s-only {
        display: none !important;
    }

    .size-sm {
        display: block;
    }

    .size-inline-sm {
        display: inline-flex;
    }
}

@include mq(m) {
    .size-sm-only {
        display: none !important;
    }

    .size-m {
        display: block;
    }

    .size-inline-m {
        display: inline-flex;
    }
}

@include mq(ml) {
    .size-m-only {
        display: none !important;
    }

    .size-ml {
        display: block;
    }

    .size-inline-ml {
        display: inline-flex;
    }
}

@include mq(l) {
    .size-ml-only {
        display: none !important;
    }

    .size-l {
        display: block;
    }

    .size-inline-l {
        display: inline-flex;
    }
}

@include mq(xl) {
    .size-l-only {
        display: none !important;
    }

    .size-xl {
        display: block;
    }

    .size-inline-xl {
        display: inline-flex;
    }
}

@include mq(xxl) {
    .size-xl-only {
        display: none !important;
    }

    .size-xxl {
        display: block;
    }

    .size-inline-xxl {
        display: inline-flex;
    }
}

@include mq(xxxl) {
    .size-xxl-only {
        display: none !important;
    }

    .size-xxxl {
        display: block;
    }

    .size-inline-xxxl {
        display: inline-flex;
    }
}
