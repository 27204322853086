﻿.hamburgher {
    display: block;
    width: 26px;
    height: 14px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: z(sur-navbar);

    &__wrapper {
        background-color: transparent;
        border: 0;  
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    &__row {
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background: #fff;
        border-radius: 0;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1), &:nth-child(4) {
            width: 80%;
            transform: translate(-50%, 0);
            left: 50%;
        }

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2),&:nth-child(3) {
            top: 6px;
        }

        &:nth-child(4) {
            top: 13px;
        }
    }
    
    &.hamburgher--active {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            
            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            
            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }    
    }
}