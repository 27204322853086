﻿.icon {
    display: inline-block;
        
    &.icon--pico {
        height: 6px;
        width: 6px
    }

    &.icon--mini {
        height: 9px;
        width: 9px
    }

    &.icon--small {
        height: 12px;
        width: 12px
    }

    &.icon--medium {
        height: 15px;
        width: 15px
    }

    &.icon--big {
        height: 20px;
        width: 20px
    }

    &.icon--huge {
        height: 25px;
        width: 25px
    }

    &.icon--hyper {
        height: 40px;
        width: 40px;
    }

    &.icon--infinite {
        height: 50px;
        width: 50px;
    }
}
